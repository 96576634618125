import React from 'react';

import SEO from '@components/Layouts/SEO';
import { Breadcrumbs } from '@components/Blocks/Breadcrumbs';
import Layout from '@components/Layouts/Layout';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import { ReviewBlock } from '@components/szv/ReviewBlock';
import { DesignPhotobookForm } from '@components/Forms/DesignPhotobook/DesignPhotobookForm';
import { DropMenu } from '@components/szv/DropMenu';
import { PriceCard } from '@components/szv/PriceCard';
import { BlockCard } from '@components/szv/BlockCard';

import { DesktopBanner, MobileBanner } from '@components/Blocks/Banners';

import {
  howToCards,
  reviewCards,
  accordionContent,
  bannerContent,
  sliderContent,
} from '@consts/designConsts';

import styles from './design-photobook.module.scss';

export function Head() {
  return (
    <>
      <SEO
        title="Дизайн фотокниги на заказ"
        description="Закажите фотокнигу с индивидуальным дизайном в издательстве Периодика Пресс в Москве с доставкой."
      />
      <YandexTableau />
    </>
  );
}

export default function NewDesignPhotoBook() {
  const breadcrumbsPath = [
    { title: 'Главная', url: '/' },
    { title: 'Соберем за вас', url: '' },
  ];

  const scrollToTarget = () => {
    document.getElementById('form')?.scrollIntoView({ behavior: 'smooth' });
  };

  // TODO: сломаны типы, надо рефакторить баннеры
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.Breadcrumbs}>
            <Breadcrumbs path={breadcrumbsPath} />
          </div>
          <div className={styles.mainBanner}>
            <DesktopBanner
              // @ts-ignore
              customContent={bannerContent}
              customClassName={[styles.maxWidth, styles.maxHeigth]}
              isH1
            />
            {/* @ts-ignore */}
            <MobileBanner customContent={bannerContent} isH1 />
          </div>
          <div className={styles.title}>
            <h2>Как это работает</h2>
          </div>
          <div className={styles.blockCardList}>
            {howToCards.map((item) => (
              <BlockCard
                key={`BlockCard-${item.id}`}
                id={item.id}
                number={item.number}
                image={item.image}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
          <div className={styles.blockBuildCard}>
            <PriceCard scrollToTarget={scrollToTarget} />
          </div>
          <div className={styles.sliderBlock}>
            <div className={styles.title}>
              <h2>Нас выбирают за:</h2>
            </div>
            <DesktopBanner
              // @ts-ignore
              customContent={sliderContent}
              customClassName={[styles.maxWidth, styles.heightBanner]}
            />
            {/* @ts-ignore */}
            <MobileBanner customContent={sliderContent} customClassName={styles.lineHeight} />
          </div>
          <div className={styles.title}>
            <h2>Истории клиентов</h2>
          </div>
          <div className={styles.reviewBlockList}>
            {reviewCards.map((item) => (
              <ReviewBlock
                key={`ReviewBlock-${item.id}`}
                id={item.id}
                name={item.name}
                description={item.description}
                image={item.image}
              />
            ))}
          </div>

          <div id="form">
            <DesignPhotobookForm />
          </div>
          <div className={styles.title}>
            <h2>Вопросы и ответы:</h2>
          </div>
          <div className={styles.DropMenu}>
            {accordionContent.map((item) => (
              <DropMenu
                key={`DropMenu-${item.id}`}
                id={item.id}
                title={item.title}
                description={item.description}
                tariff={item.tariff}
              />
            ))}
            <div className={styles.DropMenuHelp}>
              Если у вас остались вопросы, вы можете задать их&nbsp;
              <a href="https://wa.me/79686043008?text=Здравствуйте! Хочу оформить заявку на услугу «Соберем фотокнигу за вас»">
                менеджеру
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
