import React from 'react';
import Images from '@components/Images';

import styles from './BlockCard.module.scss';

interface BlockCardArgs {
  id: number;
  number: string;
  title: string;
  image: string;
  description: string;
}

export function BlockCard({ id, number, title, description, image }: BlockCardArgs) {
  return (
    <div className={styles.card} key={id}>
      <Images src={image} alt={title} />
      <div className={styles.elipse}>
        <span>{number}</span>
      </div>
      <div className={styles.metadataCard}>
        <p>{title}</p>
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
}
