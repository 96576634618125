import React from 'react';
import cn from 'classnames';

import Images from '@components/Images';

import styles from './Review.module.scss';

interface ReviewBlockArgs {
  id: number;
  name: string;
  description: string;
  image: string;
}
export function ReviewBlock({ id, name, description, image }: ReviewBlockArgs) {
  const [isScrollingEnabled, setIsScrollingEnabled] = React.useState(false);

  const handleShowContent = () => {
    setIsScrollingEnabled(!isScrollingEnabled);
  };

  return (
    <div className={styles.reviewContainerBlock} onClick={handleShowContent} aria-hidden>
      <div
        className={cn({
          [styles.reviewBlock]: true,
          [styles.activeScroll]: isScrollingEnabled,
          [styles.heigth]: isScrollingEnabled,
        })}
        key={id}
      >
        <div className={styles.reviewTitle}>{name}</div>
        <div className={styles.reviewDescription}>{description}</div>
      </div>
      <div
        className={cn({
          [styles.hiddenContent]: true,
          [styles.activeContent]: isScrollingEnabled,
        })}
      >
        <div className={styles.show} aria-hidden onClick={handleShowContent}>
          Показать
        </div>
      </div>
      <Images
        className={cn({
          [styles.reviewImage]: true,
          [styles.opacity]: isScrollingEnabled,
        })}
        src={image}
        alt={name}
      />
    </div>
  );
}
