import React from 'react';
import cn from 'classnames';

import { tariffContent } from '@consts/designConsts';

import ArrowBlock from '@images/footer/ArrowBlock.svg';
import { TariffCard } from '../TariffCard';

import styles from './DropMenu.module.scss';

interface DropMenuArgs {
  id: number;
  title: string;
  description: string;
  tariff?: boolean;
}

export function DropMenu({ id, title, description, tariff }: DropMenuArgs) {
  const [isOpenBlock, setIsOpenBlock] = React.useState(false);

  const onChangeClick = () => {
    setIsOpenBlock(!isOpenBlock);
  };
  return (
    <div
      className={cn({
        [styles.main]: true,
        [styles.openBlock]: isOpenBlock,
      })}
      onClick={onChangeClick}
      aria-hidden
      key={id}
    >
      <div className={styles.contentMenu}>
        <div className={styles.header}>
          {title}
          <div className={styles.icon}>
            <img src={ArrowBlock} alt="arrow" />
          </div>
        </div>
      </div>
      {isOpenBlock && (
        <div className={styles.contentDropMenu}>
          {tariff && (
            <div className={styles.tariffListBlock}>
              <h2>Тарифы дополнительных услуг</h2>
              <div className={styles.tariffList}>
                {tariffContent.map((item: any) => (
                  <TariffCard
                    key={`TariffCard-${item.id}`}
                    id={item.id}
                    tariff={item.tariff}
                    price={item.price}
                    Info={item.Info}
                  />
                ))}
              </div>
            </div>
          )}
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      )}
    </div>
  );
}
