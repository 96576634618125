import React from 'react';

import styles from './TariffCard.module.scss';

interface TariffCardArgs {
  id: number;
  tariff: string;
  price: string;
  Info: {
    map: any;
    info1: string;
    info2?: string;
  };
}

export function TariffCard({ id, tariff, price, Info }: TariffCardArgs) {
  return (
    <div className={styles.blockCard} key={id}>
      <div className={styles.metadata}>
        <div className={styles.titlePrice}>
          <h2>{tariff}</h2>
          <h2 className={styles.blueColor}>{price}</h2>
        </div>
        <ul className={styles.infoPrice}>
          {Info?.map((infoItem: any) => (
            <li key={id}>
              {infoItem.info1} {infoItem?.info2 && <li>{infoItem.info2}</li>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
